import React from 'react'

// CSS
import 'styles/PrivacyDeclaration.scss'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'


function PrivacyDeclaration() {
  return (
    <Layout>
      <SEO title="Privacyverklaring" />
      <div className="container" />
    </Layout>
  )
}

export default PrivacyDeclaration